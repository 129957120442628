var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "configureChannelEdit" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "项目",
                        rules: [
                          {
                            type: "array",
                            required: true,
                            message: "请选择项目",
                            trigger: "change",
                          },
                        ],
                        prop: "communityIds",
                      },
                    },
                    [
                      _c("chosen-list-panel", {
                        attrs: {
                          list: _vm.form.communityIds,
                          disabled: _vm.chosenDisabled,
                        },
                        on: {
                          "update:list": function ($event) {
                            return _vm.$set(_vm.form, "communityIds", $event)
                          },
                          select: function ($event) {
                            _vm.isShow = true
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "渠道编码",
                        rules: [
                          {
                            required: true,
                            message: "请输入渠道编码",
                            trigger: "change",
                          },
                        ],
                        prop: "channelCode",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: { maxlength: 100, width: _vm.width },
                        model: {
                          value: _vm.form.channelCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "channelCode", $$v)
                          },
                          expression: "form.channelCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "行所号",
                        rules: [
                          {
                            required: true,
                            message: "请填写行所号",
                            trigger: "change",
                          },
                        ],
                        prop: "bankNum",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入行所号",
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.bankNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bankNum", $$v)
                          },
                          expression: "form.bankNum",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "状态",
                        rules: [
                          {
                            required: true,
                            message: "是否开启状态",
                            trigger: "change",
                          },
                        ],
                        prop: "openStatus",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.openStatus },
                        model: {
                          value: _vm.form.openStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "openStatus", $$v)
                          },
                          expression: "form.openStatus",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("multi-select", {
        attrs: {
          title: "项目列表",
          isShow: _vm.isShow,
          searchUrl: _vm.getCommunityListURL,
          headers: _vm.communityTableHeader,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          backFill: _vm.form.communityIds,
          handleData: _vm.handleData,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
          "update:backFill": function ($event) {
            return _vm.$set(_vm.form, "communityIds", $event)
          },
          "update:back-fill": function ($event) {
            return _vm.$set(_vm.form, "communityIds", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "项目名称" },
                  model: {
                    value: _vm.searchParams.communityName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityName", $$v)
                    },
                    expression: "searchParams.communityName",
                  },
                }),
                _c("v-select", {
                  attrs: { options: _vm.communityStatusOps, label: "项目状态" },
                  model: {
                    value: _vm.searchParams.communityStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityStatus", $$v)
                    },
                    expression: "searchParams.communityStatus",
                  },
                }),
                _c("v-select", {
                  attrs: { options: _vm.communityStageOps, label: "项目阶段" },
                  model: {
                    value: _vm.searchParams.communityStage,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityStage", $$v)
                    },
                    expression: "searchParams.communityStage",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }