<template>
  <div class="configureChannelEdit">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block>
          <el-form-item
            label="项目"
            :rules="[
              {
                type: 'array',
                required: true,
                message: '请选择项目',
                trigger: 'change',
              },
            ]"
            prop="communityIds"
          >
            <chosen-list-panel
              :list.sync="form.communityIds"
              :disabled="chosenDisabled"
              @select="isShow = true"
            />
          </el-form-item>
          <el-form-item
            label="渠道编码"
            :rules="[
              { required: true, message: '请输入渠道编码', trigger: 'change' },
            ]"
            prop="channelCode"
          >
            <v-input
              v-model="form.channelCode"
              :maxlength="100"
              :width="width"
            />
          </el-form-item>
          <el-form-item label="行所号" :rules="[
              {
                required: true,
                message: '请填写行所号',
                trigger: 'change',
              },
            ]" prop="bankNum">
            <v-input
              v-model="form.bankNum"
              placeholder="请输入行所号"
              :width="width"
            />
          </el-form-item>
          <el-form-item
            label="状态"
            :rules="[
              { required: true, message: '是否开启状态', trigger: 'change' },
            ]"
            prop="openStatus"
          >
          <v-select v-model="form.openStatus" :options="openStatus"/>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
    <multi-select
      title="项目列表"
      :isShow.sync="isShow"
      :searchUrl="getCommunityListURL"
      :headers="communityTableHeader"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :backFill.sync="form.communityIds"
      :handleData="handleData"
    >
      <template #searchSlot>
        <v-input v-model="searchParams.communityName" label="项目名称" />
        <v-select
          v-model="searchParams.communityStatus"
          :options="communityStatusOps"
          label="项目状态"
        />
        <v-select
          v-model="searchParams.communityStage"
          :options="communityStageOps"
          label="项目阶段"
        />
      </template>
    </multi-select>
  </div>
</template>

<script>
import {
  queryDetailURL,
  createBaseInfoURL,
  updateBaseInfoURL,
  getCommunityListURL,
  getCommunityStageOpsURL,
} from "./api";
import { setCommunityStatusOps, communityStatusMap,setOpenStatusOps,openStatus } from "./map";
import {
  Col2Detail,
  Col2Block,
  ChosenListPanel,
  MultiSelect,
} from "components/bussiness";

export default {
  name: "CustomFormForm",
  components: {
    Col2Detail,
    Col2Block,
    ChosenListPanel,
    MultiSelect,
  },
  data() {
    return {
      width: 200,
      chosenDisabled:false,
      isShow: false,
      getCommunityListURL,
      openStatus,
      communityTableHeader: [
        {
          prop: "communityName",
          label: "项目名称",
        },
        {
          prop: "communityStatusText",
          label: "项目状态",
        },
        {
          prop: "communityStage",
          label: "项目阶段",
        },
        {
          prop: "regionName",
          label: "所属公司",
        },
      ],
      communityStatusOps: setCommunityStatusOps(1),
      communityStageOps: [
        {
          text: "全部",
          value: undefined,
        },
      ],
      searchParams: {
        communityName: "",
        communityStatus: 0,
        communityStage: undefined,
        isNormal: 1,
      },
      responseParams: {
        id: "id",
        name: "communityName",
      },
      responseKey: {
        id: "id",
        name: "text",
      },
      communityListText: "",
      form: {
        id: undefined,
        communityName:"",
        bankNum: "",
        channelCode: "",
        communityIds: [],
        openStatus:1
      },
      submitConfig: {
        submitUrl: "",
        submitMethod: "POST",
        queryUrl: queryDetailURL,
      },
    };
  },
  created() {
    this.getCommunityStageOps();
  },
  mounted() {
    const { id, view } = this.$route.query;
    if (id !== undefined) {
      this.submitConfig.submitUrl = updateBaseInfoURL;
      this.$refs.formPanel.getData({
        id,
      });
      this.chosenDisabled=true
    } else {
      this.$setBreadcrumb("新增");
      this.submitConfig.submitUrl = createBaseInfoURL;
    }
  },
  watch: {},
  computed: {
    userType() {
      return this.$store.state.userInfo.userType;
    }
  },
  methods: {
    async getCommunityStageOps() {
      const { status, data } = await this.$axios.get(getCommunityStageOpsURL);
      if (status === "100") {
        if (Array.isArray(data) && data.length) {
          data.forEach((item) => {
            this.communityStageOps.push({
              text: item.value,
              value: item.id,
            });
          });
        }
      }
    },
    handleData(data) {
      data.communityStatusText = communityStatusMap[data.communityStatus];
    },
    update(data) {
      console.log(data,222)
        data.communityIds = [{
            text: data.communityName,
            id: data.communityId,
          }]

      Object.keys(this.form).forEach((key) => {
        if (data[key] !== undefined) {
          this.form[key] = data[key];
        }
      });
    },
    submitBefore(data) {
      const communityIds = [];
      if (Array.isArray(data.communityIds) && data.communityIds.length) {
        data.communityIds.forEach((item) => {
          communityIds.push(item.id);
        });
      }
      data.communityIds = communityIds;

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.configureChannelEdit {
}
</style>
