

// 获取列表
const queryListURL = `${API_CONFIG.butlerBaseURL}gfBank/configuration/list`


// 新增基本信息
const createBaseInfoURL = `${API_CONFIG.butlerBaseURL}gfBank/configuration/insert`
// 更新基本信息
const updateBaseInfoURL = `${API_CONFIG.butlerBaseURL}gfBank/configuration/update`
// 查询详情
const queryDetailURL = `${API_CONFIG.butlerBaseURL}gfBank/configuration/detail`
// 查询项目列表
const getCommunityListURL = `${API_CONFIG.controlBaseURL}community/getCommunityList`
// 查询项目阶段选项
const getCommunityStageOpsURL = `${API_CONFIG.baseURL}serverCodewordAction!getProjectTypes.action`





export {
  queryListURL,
  createBaseInfoURL,
  updateBaseInfoURL,
  queryDetailURL,
  getCommunityListURL,
  getCommunityStageOpsURL
}
